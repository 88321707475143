<template>
  <button
    type="button"
    class="a-button"
    :title="title"
    :class="[
      className,
      weight,
      link && 'a-button--link',
    ]"
    :style="[
      customStyle,
    ]"
  >
    <slot />
  </button>
</template>

<script setup lang="ts">
interface Props {
  className?: '',
  sizeLg?: { size: number, line: number },
  sizeSm?: { size: number, line: number },
  link?: boolean,
  customStyle?: string,
  weight?: 'extra-bold' | 'bold' | 'regular',
  title?: string,
}

const props = withDefaults(defineProps<Props>(), {
  className: '',
  sizeLg: { size: 40, line: 48 },
  sizeSm: { size: 24, line: 32 },
  link: false,
  customStyle: '',
  weight: 'bold',
  title: '',
});

const desktopSize = props.sizeLg.size + 'px';
const desktopLineHeight = props.sizeLg.line + 'px';
const mobileSize = props.sizeSm.size + 'px';
const mobileLineHeight = props.sizeSm.line + 'px';

</script>

<style scoped lang="postcss">
.a-button {
  font-family: 'ALS Gorizont Variable', sans-serif;
  text-transform: uppercase;
  color: var(--white-color);
  cursor: pointer;
  padding: 0;
  text-align: left;
  font-weight: 700;

  font-size: v-bind(desktopSize);
  line-height: v-bind(desktopLineHeight);

  @media screen and (max-width: 768px) {
    font-size: v-bind(mobileSize);
    line-height: v-bind(mobileLineHeight);
  }

  &.regular {
    font-weight: 500;
  }
  &.bold {
    font-weight: 700;
  }
  &.extra-bold {
    font-weight: 900;
  }

  &--link {
    position: relative;
    text-decoration: underline;
    text-decoration-color: rgba(255, 255, 255, 0.27);

    text-decoration-thickness: 15%;

    transition: text-decoration-color .35s;

    &:hover {
      text-decoration-color: var(--white-color);
    }

    @media screen and (max-width: 768px) {
    }
  }
}
</style>
